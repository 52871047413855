import React, { useState, useRef, useEffect, useCallback } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { Worker, Viewer } from '@react-pdf-viewer/core';
import '@react-pdf-viewer/core/lib/styles/index.css';
import mammoth from 'mammoth';
import { useDrag, useDrop } from 'react-dnd';
import './DocumentPreview.css';
import PdfViewer from './PdfViewer';

const ItemTypes = {
  SIGNATURE: 'signature',
};

const DocumentPreview = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const { file, pdfLink, recipients = [] } = location.state || {};
  const [signatures, setSignatures] = useState([]);
  console.log("signatures", signatures);
  const [selectedRecipient, setSelectedRecipient] = useState(recipients[0]?.email || '');
  const [recipientList, setRecipientList] = useState(
    recipients.map((recipient) => ({
      ...recipient,
      color: recipient.color || `#${Math.floor(Math.random() * 16777215).toString(16)}`,
    }))
  );
  const [isEditingSignature, setIsEditingSignature] = useState(false);
  const [activeSignature, setActiveSignature] = useState(null);
  const [optionsSignature, setOptionsSignature] = useState(null);
  console.log("optionsSignature", optionsSignature);
  const [signatureType, setSignatureType] = useState('');
  const [typedSignature, setTypedSignature] = useState('');
  const [uploadedSignature, setUploadedSignature] = useState(null);
  const [font, setFont] = useState('Arial');
  const [color, setColor] = useState('#000000');
  const docxContainerRef = useRef(null);
  const canvasRef = useRef(null);
  const [pageNumber, setPageNumber] = useState(1);
  const [getFileType, setGetFileType] = useState(file.type);
  const [showRecipientModal, setShowRecipientModal] = useState(false);
  const [newRecipient, setNewRecipient] = useState({ name: '', email: '', phone: '' });
  const [fileUrl, setFileUrl] = useState(null);
  const [docxContent, setDocxContent] = useState(null);
  const [position, setPosition] = useState({ top: 0, left: 0 });
  const redBoxRef = useRef(null);
  const [errorMessage, setErrorMessage] = useState('');

  useEffect(() => {
    if (file) {
      const url = URL.createObjectURL(file);
      setFileUrl(url);

      if (file.type === 'application/vnd.openxmlformats-officedocument.wordprocessingml.document') {
        const convertDocxToHtml = async () => {
          try {
            const arrayBuffer = await file.arrayBuffer();
            const { value } = await mammoth.convertToHtml({ arrayBuffer });
            setDocxContent(value);
          } catch (error) {
            console.error("Error converting .docx file:", error);
          }
        };
        convertDocxToHtml();
      }

      return () => {
        URL.revokeObjectURL(url);
      };
    }
  }, [file]);

  const getRandomPosition = () => {
    const container = redBoxRef.current;
    if (container && container.offsetWidth && container.offsetHeight) {
      const signatureWidth = 100;
      const signatureHeight = 50;
      const maxX = container.offsetWidth - signatureWidth;
      const maxY = container.offsetHeight - signatureHeight;

      return {
        x: Math.max(0, Math.floor(Math.random() * maxX)),
        y: Math.max(0, Math.floor(Math.random() * maxY)),
      };
    }
    return { x: 0, y: 0 }; // Fallback if container is not available or its size is 0
  };

  const handleAddSignature = () => {
    const randomPosition = getRandomPosition();
    setSignatures([
      ...signatures,
      {
        email: selectedRecipient,
        position: randomPosition,
        signature: <span>Signature</span>,
        page: pageNumber
      },
    ]);
  };

  const handleEditSignature = (signature) => {
    setActiveSignature(signature);
    setIsEditingSignature(true);
    setOptionsSignature(null);
  };

  const handleSaveSignature = () => {
    setSignatures(
      signatures.map((sig) =>
        sig === activeSignature
          ? { ...sig, signature: generateSignatureContent(), position: sig.position }
          : sig
      )
    );
    setIsEditingSignature(false);
    setActiveSignature(null);
    setTypedSignature('');
    setUploadedSignature(null);
  };

  const handleCopySignature = (signature) => {
    const randomPosition = getRandomPosition();
    const copiedSignature = { ...signature, position: randomPosition };
    setSignatures([...signatures, copiedSignature]);
    setOptionsSignature(null);
  };

  const handleDeleteSignature = (signature) => {
    setSignatures(signatures.filter((sig) => sig !== signature));
    setOptionsSignature(null);
  };

  const generateSignatureContent = () => {
    const style = { fontFamily: font, color, maxWidth: '100%', maxHeight: '100%' };
    const recipientName = recipientList.find((r) => r.email === selectedRecipient)?.name || 'Signature';

    if (signatureType === 'typed') {
      return <span style={style}>{typedSignature || recipientName}</span>;
    } else if (signatureType === 'upload' && uploadedSignature) {
      return <img src={uploadedSignature} alt="Uploaded Signature" style={style} />;
    } else if (signatureType === 'draw') {
      const drawnImage = canvasRef.current.toDataURL();
      return <img src={drawnImage} alt="Drawn Signature" style={style} />;
    }
    return '';
  };

  const handleSignatureUpload = (event) => {
    const file = event.target.files[0];
    if (file) {

      if (!file.type.startsWith('image/')) {
        setErrorMessage('No File Support');
        return;
      }

      const reader = new FileReader();
      reader.onloadend = () => {
        setUploadedSignature(reader.result);
      };
      reader.readAsDataURL(file);
    }
  };

  const setupDrawingCanvas = useCallback(() => {
    if (canvasRef.current) {
      const ctx = canvasRef.current.getContext('2d');
      ctx.strokeStyle = color;
      ctx.lineWidth = 2;
      let isDrawing = false;

      const startDrawing = (e) => {
        e.preventDefault();
        const rect = canvasRef.current.getBoundingClientRect(); // Get the canvas bounding rectangle
        ctx.beginPath();
        ctx.moveTo(e.clientX - rect.left, e.clientY - rect.top); // Calculate starting point based on bounding rectangle
        isDrawing = true;
      };

      const stopDrawing = () => {
        isDrawing = false;
        ctx.closePath();
      };

      const draw = (e) => {
        if (!isDrawing) return;
        const rect = canvasRef.current.getBoundingClientRect(); // Get the canvas bounding rectangle
        ctx.lineTo(e.clientX - rect.left, e.clientY - rect.top); // Calculate current point based on bounding rectangle
        ctx.stroke();
      };

      // Add event listeners for drawing
      canvasRef.current.addEventListener('mousedown', startDrawing);
      canvasRef.current.addEventListener('mouseup', stopDrawing);
      canvasRef.current.addEventListener('mouseleave', stopDrawing);
      canvasRef.current.addEventListener('mousemove', draw);

      // Clean up event listeners on unmount
      return () => {
        canvasRef.current.removeEventListener('mousedown', startDrawing);
        canvasRef.current.removeEventListener('mouseup', stopDrawing);
        canvasRef.current.removeEventListener('mouseleave', stopDrawing);
        canvasRef.current.removeEventListener('mousemove', draw);
      };
    }
  }, [color]);


  useEffect(() => {
    if (signatureType === 'draw') {
      setupDrawingCanvas();
    }
  }, [signatureType, setupDrawingCanvas]);

  const SignatureItem = ({ signature, index, redBoxRef }) => {
    const [position, setPosition] = useState({ top: signature.position.x, left: signature.position.y });
    const [optionsSignature, setOptionsSignature] = useState(null);
    const signatureRef = useRef(null);
    const recipientColor = recipientList.find((r) => r.email === signature.email)?.color;

    let isDragging = false;
    let startX = 0;
    let startY = 0;

    const handleMouseDown = (e) => {
      e.preventDefault();

      startX = e.clientX;
      startY = e.clientY;
      isDragging = false;

      const offsetX = e.clientX - position.left;
      const offsetY = e.clientY - position.top;

      const handleMouseMove = (e) => {
        const newLeft = e.clientX - offsetX;
        const newTop = e.clientY - offsetY;

        if (Math.abs(e.clientX - startX) > 5 || Math.abs(e.clientY - startY) > 5) {
          isDragging = true;
        }

        const redBox = redBoxRef.current;
        if (redBox) {
          const maxLeft = redBox.clientWidth - 100;
          const maxTop = redBox.clientHeight - 100;

          setPosition((prev) => ({
            left: Math.min(Math.max(0, newLeft), maxLeft),
            top: Math.min(Math.max(0, newTop), maxTop),
          }));
        }
      };

      const handleMouseUp = () => {
        document.removeEventListener('mousemove', handleMouseMove);
        document.removeEventListener('mouseup', handleMouseUp);

        if (isDragging) {
          isDragging = false;
        } else {
          handleClick();
        }
      };

      document.addEventListener('mousemove', handleMouseMove);
      document.addEventListener('mouseup', handleMouseUp);
    };

    const handleClick = () => {
      setOptionsSignature(signature);
    };

    return (
      <>

        {/* <div
                ref={signatureRef}
                className="signature-placeholder"
                style={{
                    position: 'absolute',
                    top: `${position.top}px`,
                    left: `${position.left}px`,
                    width: '120px',
                    height: '50px',
                    backgroundColor: recipientColor || 'yellow',
                    cursor: 'pointer',
                    opacity: 0.5,
                }}
                onMouseDown={handleMouseDown}
            >
                <div
                    style={{
                        width: '100%',
                        height: '100%',
                        overflow: 'hidden',
                        textOverflow: 'ellipsis',
                        fontFamily: 'Arial',
                        fontSize: '14px',
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'center',
                    }}
                >
                    {signature.signature || <span>Signature</span>}
                </div>
            </div> */}


        <div
          className="signature-placeholder"
          style={{
            position: 'absolute',
            top: `${position.top}px`,
            left: `${position.left}px`,
            width: '120px',
            height: '50px',
            cursor: 'pointer',
            border: '2px dotted orange', // Dotted border around the box
            padding: '20px',  // Add padding to give space inside the border
          }}
        >
          <div
            style={{
              width: '100%',
              height: '100%',
              overflow: 'hidden',
              textOverflow: 'ellipsis',
              fontFamily: 'Arial',
              fontSize: '14px',
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
              backgroundColor: recipientColor || 'yellow',
              opacity: 0.6
            }}
            onMouseDown={handleMouseDown}
            ref={signatureRef}

          >
            {signature.signature || <span>Signature</span>}
          </div>
        </div>


        {optionsSignature === signature && (
          <div
            className="signature-options-overlay"
            style={{
              position: 'absolute',
              top: `${position.top - 55}px`,
              left: `${position.left}px`,
              width: '120px',
              backgroundColor: 'rgba(0, 0, 0, 0.8)',
              borderRadius: '5px',
              padding: '5px',
              zIndex: 1001,
              display: 'flex',
              flexDirection: 'row',
              color: '#fff',
            }}
          >
            <button
              className="close-button"
              onClick={(e) => {
                e.stopPropagation();
                setOptionsSignature(null);
              }}
              style={{
                backgroundColor: 'transparent',
                border: 'none',
                fontSize: '16px',
                cursor: 'pointer',
                color: '#fff',
                alignSelf: 'flex-end',
              }}
            >
              &times;
            </button>
            <button onClick={() => handleEditSignature(signature)} style={{ color: '#fff', background: 'none', border: 'none' }}>
              Edit
            </button>
            <button onClick={() => handleCopySignature(signature)} style={{ color: '#fff', background: 'none', border: 'none' }}>
              Copy
            </button>
            <button onClick={() => handleDeleteSignature(signature)} style={{ color: '#fff', background: 'none', border: 'none' }}>
              Delete
            </button>
          </div>
        )}
      </>
    );
  };

  const [{ }, drop] = useDrop({
    accept: ItemTypes.SIGNATURE,
    drop: (item, monitor) => {
      const delta = monitor.getDifferenceFromInitialOffset();
      const index = item.index;
      const updatedSignatures = [...signatures];

      const container = docxContainerRef.current;
      if (!container) return;

      const containerWidth = container.clientWidth;
      const containerHeight = container.clientHeight;

      const signatureWidth = 210; // Adjusted to the actual width of the signature element
      const signatureHeight = 45; // Adjusted to the actual height of the signature element

      // Calculate the new position based on the drag delta
      let newX = updatedSignatures[index].position.x + delta.x;
      let newY = updatedSignatures[index].position.y + delta.y;

      // Constrain the signature within container bounds
      if (newX < 0) {
        newX = 0;
      } else if (newX + signatureWidth > containerWidth) {
        newX = containerWidth - signatureWidth;
      }

      if (newY < 0) {
        newY = 0;
      } else if (newY + signatureHeight > containerHeight) {
        newY = containerHeight - signatureHeight;
      }

      // Update the signature position with constrained coordinates
      updatedSignatures[index] = {
        ...updatedSignatures[index],
        position: { x: newX, y: newY },
      };

      setSignatures(updatedSignatures);
      setOptionsSignature(null);
    },
  });

  const renderFilePreview = () => {
    if (!fileUrl) return <p>Loading preview...</p>;

    const fileType = file.type;

    if (fileType === 'application/pdf') {
      return (
        <Worker workerUrl={`//cdnjs.cloudflare.com/ajax/libs/pdf.js/2.16.105/pdf.worker.min.js`}>
          <Viewer fileUrl={fileUrl} />
        </Worker>
      );
    } else if (fileType.includes('image')) {
      return <img src={fileUrl} alt="Preview" style={{ maxWidth: '100%', height: '600px', objectFit: 'contain' }} />;
    } else if (fileType === 'application/vnd.openxmlformats-officedocument.wordprocessingml.document') {
      // Render the .docx content converted by mammoth
      return (
        <div
          ref={docxContainerRef}
          style={{
            width: '100%',
            height: 'auto',
            overflow: 'hidden',
            border: '1px solid #ddd',
            padding: '20px',
          }}
          dangerouslySetInnerHTML={{ __html: docxContent }}
        />
      );
    } else if (fileType === 'application/vnd.openxmlformats-officedocument.presentationml.presentation' ||
      fileType === 'application/vnd.ms-powerpoint') {
      return (
        <iframe
          src={`https://view.officeapps.live.com/op/embed.aspx?src=${encodeURIComponent(fileUrl)}`}
          width="100%"
          height="600px"
          style={{ border: 'none' }}
          title="PowerPoint Preview"
        ></iframe>
      );
    } else {
      return <p>Unsupported file type for preview.</p>;
    }
  };
  const handleAddRecipient = () => setShowRecipientModal(true);

  const handleSaveRecipient = () => {
    const newColor = `#${Math.floor(Math.random() * 16777215).toString(16)}`;
    setRecipientList([...recipientList, { ...newRecipient, color: newColor }]);
    setSelectedRecipient(newRecipient.email);
    setNewRecipient({ name: '', email: '', phone: '' });
    setShowRecipientModal(false);
  };

  const renderRecipientModal = () => (
    <div className="recipient-modal">
      <div className="recipient-modal-content">
        <h3>Add Recipient</h3>
        <input
          type="text"
          placeholder="Enter recipient name"
          value={newRecipient.name}
          onChange={(e) => setNewRecipient({ ...newRecipient, name: e.target.value })}
          className="input-field"
        />
        <input
          type="email"
          placeholder="Enter recipient email"
          value={newRecipient.email}
          onChange={(e) => setNewRecipient({ ...newRecipient, email: e.target.value })}
          className="input-field"
        />
        <input
          type="text"
          placeholder="Enter phone number"
          value={newRecipient.phone}
          onChange={(e) => setNewRecipient({ ...newRecipient, phone: e.target.value })}
          className="input-field"
        />
        <button onClick={handleSaveRecipient} className="save-button">Save</button>
        <button onClick={() => setShowRecipientModal(false)} className="cancel-button">Cancel</button>
      </div>
    </div>
  );

  const renderSignaturePopup = () => (
    <div className="signature-popup">
      <div className="signature-popup-content">
        <h3>Edit Signature for {recipientList.find((r) => r.email === selectedRecipient)?.name}</h3>
        <div className="signature-options">
          <button onClick={() => setSignatureType('typed')}>Type</button>
          <button onClick={() => setSignatureType('draw')}>Draw</button>
          <button onClick={() => setSignatureType('upload')}>Upload</button>
        </div>

        {signatureType === 'typed' && (
          <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', gap: '16px' }}>
            <div style={{ display: 'flex', alignItems: 'center', gap: '16px' }}>
              {/* Font Selection Dropdown */}
              <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'flex-start' }}>
                <p style={{ marginBottom: '8px' }}>Choose font:</p>
                <select
                  value={font}
                  onChange={(e) => setFont(e.target.value)}
                  className="font-select"
                  style={{
                    width: '150px',
                    padding: '8px',
                    fontFamily: font,
                    fontSize: '16px',
                    cursor: 'pointer',
                  }}
                >
                  <option value="Dancing Script" style={{ fontFamily: 'Dancing Script' }}>AHMAD MURTAZA</option>
                  <option value="Great Vibes" style={{ fontFamily: 'Great Vibes' }}>AHMAD MURTAZA</option>
                  <option value="Pacifico" style={{ fontFamily: 'Pacifico' }}>AHMAD MURTAZA</option>
                  <option value="Satisfy" style={{ fontFamily: 'Satisfy' }}>AHMAD MURTAZA</option>
                </select>
              </div>

              <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'flex-start' }}>
                <p style={{ marginBottom: '8px' }}>Choose color:</p>
                <div style={{ display: 'flex', gap: '8px' }}>
                  {['#000000', '#FF0000', '#0000FF', '#00FF00'].map((colorOption) => (
                    <div
                      key={colorOption}
                      onClick={() => setColor(colorOption)}
                      style={{
                        width: '10px',
                        height: '10px',
                        borderRadius: '50%',
                        backgroundColor: colorOption,
                        cursor: 'pointer',
                        border: color === colorOption ? '2px solid #555' : '2px solid transparent',
                      }}
                    />
                  ))}
                </div>
              </div>
            </div>

            {/* Preview Area */}
            <div
              className="typed-signature-preview"
              style={{ fontFamily: font, color: color, fontSize: '20px', marginTop: '20px', fontWeight: 'bold' }}
            >
              {typedSignature || recipientList.find((r) => r.email === selectedRecipient)?.name}
            </div>

            {/* Signature Input */}
            <input
              type="text"
              placeholder="Type your signature"
              value={typedSignature}
              onChange={(e) => setTypedSignature(e.target.value)}
              className="input-field"
              style={{
                padding: '8px',
                width: '100%',
                maxWidth: '250px',
                fontSize: '16px',
                fontFamily: font,
                color: color,
                marginTop: '8px'
              }}
            />
          </div>
        )}




        {signatureType === 'draw' && (
          <div>
            <label>Drawing Color: </label>
            <input type="color" value={color} onChange={(e) => setColor(e.target.value)} />
            <canvas
              ref={canvasRef}
              className="signature-canvas"
              width="300"
              height="100"
              style={{ border: '1px solid black', cursor: 'crosshair' }}
            />
          </div>
        )}

        {signatureType === 'upload' && (
          <div className="upload-section" style={{ border: '1px dashed #ccc', padding: '20px', textAlign: 'center' }}>
            <p>Drag & drop a file here or</p>
            <div style={{ display: "flex", flexDirection: "column", gap: "20px" }}>
              <button className="choose-file-button" onClick={() => document.getElementById('uploadInput').click()}>
                Choose a Local File
              </button>
              {errorMessage}
            </div>
            <input
              id="uploadInput"
              type="file"
              onChange={handleSignatureUpload}
              style={{ display: 'none' }}
            />
            {uploadedSignature && (
              <div style={{ marginTop: '20px' }}>
                <img src={uploadedSignature} alt="Preview" style={{ maxWidth: '50%', height: 'auto' }} />
              </div>
            )}
          </div>
        )}

        <button onClick={handleSaveSignature} className="save-button">Save</button>
        <button className="close-popup-button" onClick={() => setIsEditingSignature(false)}>Close</button>
      </div>
    </div>
  );

  const handleSendClick = () => {
    navigate('/review-recipients', { state: { recipients: recipientList, documentName: file?.name, file } });
  };

  return (
    <div className="document-preview-container">
      <h2>Preview Document: {file?.name}</h2>
      <div className="document-content" ref={drop}>
        <div
          ref={redBoxRef}
          className="pdf-viewer"
          style={{
            height: 'auto',
            border: '1px solid #ddd',
            position: 'relative',
            paddingBottom: '10px',
          }}
        >
          {
            (pdfLink || getFileType === 'application/pdf') ? (
              <PdfViewer
                pdfLink={pdfLink}
                fileUrl={fileUrl}
                pageNumber={pageNumber}
                setPageNumber={setPageNumber}
                signatures={signatures}
              />
            ) : (
              renderFilePreview()
            )
          }

          {
            signatures
              .filter((signature) => signature.page === pageNumber)
              .map((signature, index) => (
                <SignatureItem key={index} signature={signature} index={index} redBoxRef={redBoxRef}
                />

              ))
          }
        </div>
        <div className="sidebar">
          <h3>Recipients</h3>
          <select
            value={selectedRecipient}
            onChange={(e) => setSelectedRecipient(e.target.value)}
            className="recipient-select"
            style={{ color: recipientList.find(r => r.email === selectedRecipient)?.color }}
          >
            {recipientList.map((recipient, index) => (
              <option key={index} value={recipient.email} style={{ color: recipient.color }}>
                {`${recipient.name} (${recipient.email})`}
              </option>
            ))}
          </select>
          <button className="add-recipient-button" onClick={handleAddRecipient}>Add Recipient</button>
          <button className="add-signature-button" onClick={handleAddSignature}>Add Signature</button>
          <button className="send-button" onClick={handleSendClick} style={{ marginTop: '10px' }}>Send</button>
        </div>
      </div>
      {showRecipientModal && renderRecipientModal()}
      {isEditingSignature && renderSignaturePopup()}
    </div>
  );
};

export default DocumentPreview;
