import React, { useState, useEffect, useLayoutEffect, useRef } from 'react';
import * as pdfjsLib from 'pdfjs-dist/webpack';

pdfjsLib.GlobalWorkerOptions.workerSrc = `https://cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjsLib.version}/pdf.worker.min.js`;

const PdfViewer = ({ pdfLink, fileUrl, pageNumber, setPageNumber, signatures = [] }) => {
  const [pdf, setPdf] = useState(null);
  const [totalPages, setTotalPages] = useState(0);
  const containerRef = useRef(null); // Initialize the ref
  const pageHeight = useRef([]); // Store the heights of all pages

  // Load the PDF when the component mounts or when fileUrl/pdfLink changes
  useEffect(() => {
    const loadPdf = async () => {
      try {
        const pdfSource = fileUrl || pdfLink;
        const loadedPdf = await pdfjsLib.getDocument(pdfSource).promise;
        setPdf(loadedPdf);
        setTotalPages(loadedPdf.numPages);
      } catch (error) {
        console.error('Error loading PDF:', error);
      }
    };

    loadPdf();
  }, [pdfLink, fileUrl]);

  const renderPage = async (num) => {
    if (!pdf || !containerRef.current) return;
  
    const page = await pdf.getPage(num);
    const canvas = document.createElement('canvas');
    const context = canvas.getContext('2d');
    const viewport = page.getViewport({ scale: 1.5 });
  
    // Set canvas width and height based on the viewport
    canvas.width = viewport.width;
    canvas.height = viewport.height;
  
    // Render the PDF page onto the canvas
    await page.render({ canvasContext: context, viewport }).promise;
  
    // Create a container for the page
    const pageContainer = document.createElement('div');
    pageContainer.style.position = 'relative';
    pageContainer.style.margin = '10px 0';
    pageContainer.style.width = '100%'; // Ensure the page container takes full width
    pageContainer.style.height = `${canvas.height}px`;  // Set container height to match the canvas
  
    // Append the canvas to the page container
    pageContainer.appendChild(canvas);
  
    // Add signatures to the page
    signatures
      .filter((signature) => signature?.page === num)
      .forEach((signature) => {
        if (signature?.x !== undefined && signature?.y !== undefined && signature?.image) {
          const signatureElement = document.createElement('div');
          signatureElement.style.position = 'absolute'; // Position relative to the page container
          signatureElement.style.left = `${Math.min(signature.x, viewport.width - 100)}px`; // Prevent overflow on X-axis
          signatureElement.style.top = `${signature.y + containerRef.current.scrollTop}px`; // Adjust for scroll position
          signatureElement.style.zIndex = 10;
  
          const img = document.createElement('img');
          img.src = signature.image;
          img.style.maxWidth = '100px'; // Limit the width of the signature image
          img.style.maxHeight = '50px'; // Limit the height of the signature image
          img.style.pointerEvents = 'none'; // Disable interactions with the signature
          img.style.objectFit = 'contain'; // Ensure the image scales correctly
          img.style.position = 'absolute'; // Keep the image within the page container
  
          signatureElement.appendChild(img);
          pageContainer.appendChild(signatureElement);
        }
      });
  
    // Append the page container to the main container
    if (containerRef.current) {
      containerRef.current.appendChild(pageContainer);
    }
  
    // Save the height of the page for scroll detection
    pageHeight.current[num - 1] = pageContainer.offsetHeight;
  
    // Update the page number in the parent when a page is rendered
    setPageNumber(num);
  };
  
  // When the PDF is loaded, render all pages
  useLayoutEffect(() => {
    if (containerRef.current && pdf && totalPages > 0) {
      // Clear the container before rendering new pages
      containerRef.current.innerHTML = '';
  
      // Render all pages
      for (let i = 1; i <= totalPages; i++) {
        renderPage(i);
      }
    }
  }, [pdf, totalPages]);
  
  // Scroll handler to detect the current page
  const handleScroll = () => {
    if (!containerRef.current || pageHeight.current.length === 0) return;
  
    const scrollTop = containerRef.current.scrollTop;
    let currentPage = 1;
    let accumulatedHeight = 0;
  
    for (let i = 0; i < pageHeight.current.length; i++) {
      accumulatedHeight += pageHeight.current[i];
      if (scrollTop < accumulatedHeight) {
        currentPage = i + 1;
        break;
      }
    }
  
    if (currentPage !== pageNumber) {
      setPageNumber(currentPage);
    }
  };
  
  // Attach scroll event listener
  useEffect(() => {
    const container = containerRef.current;
    if (container) {
      container.addEventListener('scroll', handleScroll);
    }
  
    return () => {
      if (container) {
        container.removeEventListener('scroll', handleScroll);
      }
    };
  }, [pageNumber]);
  
  return (
    <div
      ref={containerRef}
      style={{
        height: '90vh',
        overflowY: 'auto', // Allow vertical scroll
        overflowX: 'hidden', // Disable horizontal scroll
        border: '1px solid #ccc',
        backgroundColor: '#f9f9f9',
        width: '100%',
        scrollbarWidth:"none"
         // Ensure the container takes the full width
      }}
    >
      {/* Pages will be dynamically appended here */}
    </div>
  );
  };

export default PdfViewer;
