import React, { useState, useRef } from 'react';

const Test = () => {
  const [position, setPosition] = useState({ top: 0, left: 0 });
  const redBoxRef = useRef(null);  // Reference to the red box

  const handleMouseDown = (e) => {
    // Prevent default to avoid unwanted page selection behavior
    e.preventDefault();

    const offsetX = e.clientX - position.left;
    const offsetY = e.clientY - position.top;

    const handleMouseMove = (e) => {
      const newLeft = e.clientX - offsetX;
      const newTop = e.clientY - offsetY;

      // Get the size of the red box dynamically
      const redBox = redBoxRef.current;
      const maxLeft = redBox.clientWidth - 100; // red box width minus yellow box width
      const maxTop = redBox.clientHeight - 100; // red box height minus yellow box height

      setPosition({
        left: Math.min(Math.max(0, newLeft), maxLeft),
        top: Math.min(Math.max(0, newTop), maxTop),
      });
    };

    const handleMouseUp = () => {
      document.removeEventListener('mousemove', handleMouseMove);
      document.removeEventListener('mouseup', handleMouseUp);
    };

    document.addEventListener('mousemove', handleMouseMove);
    document.addEventListener('mouseup', handleMouseUp);
  };

  return (
    <div
      ref={redBoxRef}  // Add the ref to the red box
      style={{
        height: '500px',
        width: '70%',
        backgroundColor: 'red',
        position: 'relative',
      }}
    >
      <div
        style={{
          position: 'absolute',
          top: `${position.top}px`,
          left: `${position.left}px`,
          height: '100px',
          width: '100px',
          backgroundColor: 'yellow',
          cursor: 'pointer',
        }}
        onMouseDown={handleMouseDown}
      ></div>
    </div>
  );
};

export default Test;


// const SignatureItem = ({ signature, index, redBoxRef }) => {

//   const [position, setPosition] = useState({ top: 50, left: 50 });
//   const signatureRef = useRef(null);
//   const recipientColor = recipientList.find((r) => r.email === signature.email)?.color;

//   const handleMouseDown = (e) => {
//     // Prevent default to avoid unwanted page selection behavior
//     e.preventDefault();

//     const offsetX = e.clientX - position.left;
//     const offsetY = e.clientY - position.top;

//     const handleMouseMove = (e) => {
//       const newLeft = e.clientX - offsetX;
//       const newTop = e.clientY - offsetY;

//       // Get the size of the red box dynamically
//       const redBox = redBoxRef.current;
//       const maxLeft = redBox.clientWidth - 100; // red box width minus signature box width
//       const maxTop = redBox.clientHeight - 100; // red box height minus signature box height

//       setPosition({
//         left: Math.min(Math.max(0, newLeft), maxLeft),
//         top: Math.min(Math.max(0, newTop), maxTop),
//       });
//     };

//     const handleMouseUp = () => {
//       document.removeEventListener('mousemove', handleMouseMove);
//       document.removeEventListener('mouseup', handleMouseUp);
//     };

//     document.addEventListener('mousemove', handleMouseMove);
//     document.addEventListener('mouseup', handleMouseUp);
//   };

//   return (
//     <>
//       <div
//         ref={signatureRef}
//         className="signature-placeholder"
//         style={{
//           position: 'absolute',
//           left: `${signature.position.x}px`,
//           top: `${signature.position.y}px`,
//           width: '120px',
//           height: '50px',
          
//           cursor: 'pointer',
//           zIndex: 1000,
//           backgroundColor: recipientColor,
//           color: '#fff',
//           borderRadius: '5px',
//           overflow: 'hidden',
//           display: 'flex',
//           alignItems: 'center',
//           justifyContent: 'center',
//         }}
//         onMouseDown={handleMouseDown}
//         onClick={(e) => {
//           e.stopPropagation();
//           setOptionsSignature(signature);
//         }}
//       >
//         <div
//           style={{
//             width: '100%',
//             height: '100%',
//             overflow: 'hidden',
//             textOverflow: 'ellipsis',
//             fontFamily: font,
//             fontSize: '14px',
//             display: 'flex',
//             alignItems: 'center',
//             justifyContent: 'center',
//           }}
//         >
//           {signature.signature || <span>Signature</span>}
//         </div>
//       </div>

//       {optionsSignature === signature && (
//         <div
//           className="signature-options-overlay"
//           style={{
//             position: 'absolute',
//             left: `${signature.position.x}px`,
//             top: `${signature.position.y - 55}px`, // Positioned below the signature box
//             width: '120px',
//             backgroundColor: 'rgba(0, 0, 0, 0.8)',
//             borderRadius: '5px',
//             padding: '5px',

//             zIndex: 1001,
//             display: 'flex',
//             flexDirection: 'row',
//             color: '#fff',
//           }}
//         >
//           <button
//             className="close-button"
//             onClick={(e) => {
//               e.stopPropagation();
//               setOptionsSignature(null);
//             }}
//             style={{
//               backgroundColor: 'transparent',
//               border: 'none',
//               fontSize: '16px',
//               cursor: 'pointer',
//               color: '#fff',
//               alignSelf: 'flex-end',

//             }}
//           >
//             &times;
//           </button>
//           <button onClick={() => handleEditSignature(signature)} style={{ color: '#fff', background: 'none', border: 'none' }}>Edit</button>
//           <button onClick={() => handleCopySignature(signature)} style={{ color: '#fff', background: 'none', border: 'none' }}>Copy</button>
//           <button onClick={() => handleDeleteSignature(signature)} style={{ color: '#fff', background: 'none', border: 'none' }}>Delete</button>
//         </div>
//       )}
//     </>
//   );
// };