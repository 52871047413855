import React from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import { DndProvider } from 'react-dnd';
import { HTML5Backend } from 'react-dnd-html5-backend';
import './App.css';
import Dashboard from './components/Dashboard';
import CreateDocument from './components/CreateDocument';
import AddRecipients from './components/AddRecipients';
import DocumentPreview from './components/DocumentPreview';
import ReviewRecipients from './components/ReviewRecipients';  // New component
import SendDocument from './components/SendDocument';  // New component
import Test from './components/Test';
function App() {
  return (
    <DndProvider backend={HTML5Backend}>
      <Router>
        <Routes>
          <Route path="/" element={<Dashboard />} />
          <Route path="/create-document" element={<CreateDocument />} />
          <Route path="/add-recipients" element={<AddRecipients />} />
          <Route path="/sign" element={<DocumentPreview />} />
          <Route path="/review-recipients" element={<ReviewRecipients />} />  {/* New Route */}
          <Route path="/send-document" element={<SendDocument />} />  {/* New Route */}
          <Route path="/test" element={<Test />} />  {/* New Route */}
          <Route path="/success" element={<h2>Document saved successfully!</h2>} />
          <Route path="*" element={<h2>Page not found</h2>} />
        </Routes>
      </Router>
    </DndProvider>
  );
}

export default App;
