import React, { useState } from 'react';
import { useLocation } from 'react-router-dom';
import './SendDocument.css';

const SendDocument = () => {
  const location = useLocation();
  const { recipients = [], documentName } = location.state || {}; // Retrieve recipients and document name from state

  const [fromEmail, setFromEmail] = useState(''); // State to hold admin's email
  const [emailMessage, setEmailMessage] = useState('');

  const handleFromEmailChange = (e) => {
    setFromEmail(e.target.value);
  };

  const handleEmailMessageChange = (e) => {
    setEmailMessage(e.target.value);
  };

  const handleSendDocument = () => {
    // Implement the logic to send the document via email here
    console.log('From:', fromEmail);
    console.log('To:', recipients.map(rec => rec.email));
    console.log('Message:', emailMessage);
    alert('Document sent successfully!');
  };

  return (
    <div className="send-document-container">
      <h2>Send Document</h2>
      <div className="form-group">
        <label>From:</label>
        <input
          type="email"
          placeholder="Your Email"
          value={fromEmail}
          onChange={handleFromEmailChange}
          className="input-field"
        />
      </div>
      <div className="form-group">
        <label>To:</label>
        <div className="recipient-list">
          {recipients.map((recipient, index) => (
            <div key={index} className="recipient-email">
              {recipient.email}
            </div>
          ))}
        </div>
      </div>
      <div className="form-group">
        <label>Email message</label>
        <textarea
          placeholder="Optional message..."
          value={emailMessage}
          onChange={handleEmailMessageChange}
          className="input-field"
        />
      </div>
      <button onClick={handleSendDocument} className="send-document-button">
        Send Document
      </button>
    </div>
  );
};

export default SendDocument;
