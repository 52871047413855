import React from 'react';
import { useNavigate } from 'react-router-dom';

const Dashboard = () => {
  const navigate = useNavigate();

  const handleCreateDocument = () => {
    navigate('/create-document');
  };

  return (
    <div className="dashboard-container">
      <h2>Welcome to Document Manager</h2>
      <button onClick={handleCreateDocument}>+ Create Document</button>
    </div>
  );
};

export default Dashboard;
