// import React, { useState } from 'react';
// import { useNavigate } from 'react-router-dom';
// import './CreateDocument.css';

// const CreateDocument = () => {
//   const [file, setFile] = useState(null);
//   console.log("File",file);
//   const [errorMessage, setErrorMessage] = useState('');
//   const navigate = useNavigate();

//   const handleFileUpload = (event) => {
//     const uploadedFile = event.target.files[0];
//     const allowedTypes = [
//       'application/pdf',
//       'application/vnd.openxmlformats-officedocument.wordprocessingml.document', // Word
//       'application/vnd.openxmlformats-officedocument.presentationml.presentation', // PowerPoint
//       'image/jpeg',
//       'image/png',
//     ];

//     if (uploadedFile) {
//       if (allowedTypes.includes(uploadedFile.type)) {
//         setFile(uploadedFile);
//         setErrorMessage('');
//       } else {
//         setFile(null);
//         setErrorMessage(
//           'Unsupported file type. Please upload a PDF, Word, PowerPoint, JPG, or PNG.'
//         );
//       }
//     }
//   };

//   const handleContinue = () => {
//     if (file) {
//       navigate('/add-recipients', { state: { file } });
//     }
//   };

//   return (
//     <div className="create-document-container">
//       <h2>Create a Document</h2>
//       <div className="upload-box">
//         <label className="file-upload-label">
//           <input
//             type="file"
//             onChange={handleFileUpload}
//             className="file-input"
//           />
//           <span>Select files</span>
//         </label>
//         <p className="file-info">
//           Supported files: PDF, Word, PowerPoint, JPG, PNG
//         </p>
//         {file && <p className="file-name">Selected file: {file.name}</p>}
//         {errorMessage && <p className="error-message">{errorMessage}</p>} 
//       </div>
//       <button
//         className="continue-button"
//         onClick={handleContinue}
//         disabled={!file}
//       >
//         Next
//       </button>
//     </div>
//   );
// };

// export default CreateDocument;


import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';
import './CreateDocument.css';

const CreateDocument = () => {
  const [file, setFile] = useState(null);
  const [errorMessage, setErrorMessage] = useState('');
  const [loading, setLoading] = useState(false);
  const [pdfLink, setPdfLink] = useState(null);
  const navigate = useNavigate();

  const handleFileUpload = async (event) => {
    const uploadedFile = event.target.files[0];
    const allowedTypes = [
      'application/pdf',
      'application/vnd.openxmlformats-officedocument.wordprocessingml.document', // Word
      'application/vnd.openxmlformats-officedocument.presentationml.presentation', // PowerPoint
      'image/jpeg',
      'image/png',
    ];

    if (uploadedFile) {
      if (allowedTypes.includes(uploadedFile.type)) {
        setFile(uploadedFile);
        setErrorMessage('');

        if (uploadedFile.type === 'application/vnd.openxmlformats-officedocument.presentationml.presentation') {
          await handlePptxConversion(uploadedFile);
        }
      } else {
        setFile(null);
        setErrorMessage(
          'Unsupported file type. Please upload a PDF, Word, PowerPoint, JPG, or PNG.'
        );
      }
    }
  };

  const handlePptxConversion = async (file) => {
    setLoading(true);
    const formData = new FormData();
    formData.append('pptxFile', file);

    try {
      const response = await axios.post('https://node-project-theta.vercel.app/convert', formData, {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
        responseType: 'blob',
      });
      

      const pdfBlob = new Blob([response.data], { type: 'application/pdf' });
      const pdfUrl = URL.createObjectURL(pdfBlob);
      setPdfLink(pdfUrl);

      setLoading(false);
    } catch (error) {
      console.error('Error during conversion:', error);
      alert('Error during conversion');
      setLoading(false);
    }
  };

  const handleContinue = () => {
    if (file) {
      navigate('/add-recipients', { state: { file,pdfLink } });
    }
  };

  return (
    <div className="create-document-container">
      <h2>Create a Document</h2>
      <div className="upload-box">
        <label className="file-upload-label">
          <input
            type="file"
            onChange={handleFileUpload}
            className="file-input"
          />
          <span>Select files</span>
        </label>
        <p className="file-info">
          Supported files: PDF, Word, PowerPoint, JPG, PNG
        </p>
        {loading && <p className="loading-message">Loading...</p>}
      </div>

      <button
        className="continue-button"
        onClick={handleContinue}
        disabled={!file || loading}
      >
        Next
      </button>
    </div>
  );
};

export default CreateDocument;

